.cardPago {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.2);
  padding: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  position: center;
  width: 80%;
  top: 15%;
  left: 5%;
  max-width: 900px;
  overflow: hidden;
}

@media (max-width:1200px) {
  .cardPago {
    overflow: scroll;
  }
}

.botonsolo {
  padding: 0;
  margin: 0;
  background-color: white;
  border-radius: 10px;
  border-color: #f5f5f5;
}

.boton {
  border-radius: 20px;
  padding-bottom: 30px;
}

.title {
  padding-left: 20px;
}

.divider {
  border-bottom: 2px solid #f5f5f5;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eb5048;
  border-radius: 50%;
  color: white;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  border-radius: 50%;
  font-weight: bold;
}

.subtitle {
  padding-left: 40px;
  font-size: 14px;
  color: darkgrey;
  font-weight: bold;
}

.total {
  padding-right: 60px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-end; */
  width: 100%;
  font-size: 20px;
}

.head {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  min-width: 600px;
}

.grid {
  padding: 0;
  padding-right: 10;
}

.myButton {
  background-color: #007c85;
  border-radius: 10px;
  border: 1px solid #ffffff;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 60px;
  text-decoration: none;
}

.myButton:hover {
  background-color: #007c85;
}

.myButton:active {
  position: relative;
  top: 1px;
}

@media (max-width: 600px) {
  .nombreusuario {
      line-height: 1;
      margin: 5px;
      padding-top: 5px;
      width: 100%;
  }
  .step {
  
      width: 100%;
    
  }
  .title {
      width: 100%;
     
  }
}

.datosprincipales {

  display: flex;
  flex-direction: column;
  line-height: 0;
  width: 100%;
  padding: 20px 0 20px 15px;
  flex-wrap: wrap;
}

.nombreusuario {
  line-height: 1;
  margin: 3px;
  padding-top: 5px;
}

.step {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 20px;
}

.espacio{
  padding-bottom: 20px;
}
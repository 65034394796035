.footer {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 350px;
    padding: 30px;
    padding-right: 100px;
    background-color: #EB5048;
   justify-content:space-evenly;
}

.card {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    padding-left: 50px;
    max-width: 400px;
    font-size: 18px;
}
@media (max-width: 600px) {
    .footer {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 700px;
        padding: 30px;
        padding-right: 100px;
        background-color: #EB5048;
       justify-content:space-evenly;
    }
    .card {
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: left;
        max-width: 400px;
        font-size: 18px;
        padding-left: 0px;
    }
  }
.card-right{
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    font-size: 24px;
    cursor: pointer;
    text-decoration: none;
    color: white;

}
.cardItem {
    display: block;
    justify-content: start;
    align-items: flex-start;
}

.logo {
    width: 300px;
    text-align: left;
    margin: 0;
    margin-left: 0;
    padding: 0;
    padding-left: 0;
}

.rrss{
    display: block;
    text-align: left;
    margin: 0;
    margin-left: 0;
    padding: 0;
    padding-left: 0;
}

.list{
    margin: 0;
    margin-left: 0;
    padding: 0;
    padding-left: 0;
    
}

.img {
    width: 25px;
}


.listRight{
    padding-top: 18px;
    margin: 0;
    
  
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
    text-decoration: none;
    font-size: 13.6px;
    color: white;
    list-style:none;
}

.link {
    font-size: 18px;
    color: black;
}
.text{
    font-size: 13.6px;
    color: white;
}
.menu{
padding-top: 10px;

}
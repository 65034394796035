body {
    background-color: #f5f5f5;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: none;
}

.contained {

    display:flex;
    flex-direction: column;
    justify-content:center ;
    align-items: center;
    min-width: 90%;
    height: 100%;
    text-decoration: none


}


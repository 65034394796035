.dataHead {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    margin: 0px 40px;
    margin-top: 20px;
    max-width: 800px;
    max-height: 400px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    min-width: 800px;
}

.encabezados {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    padding-left: 5px;

}

.table {
    margin: 0px;
}

.tit {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0px;
    margin: 0px;
    max-width: 800px;
    max-height: 400px;
    font-weight: bold;
}


.data {
    display: flex;
    /* flex-direction: row; */
    flex-direction: column;
    justify-content: space-around;
    min-width: 600px;


}

.data-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}


.cell {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
}

.cellNumero {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 100px;
}

.cellMonto {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;

}

.checkbox {

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1px;
}


.name {
    display: flex;
    height: 50px;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0px;
    font-weight: 400;
    min-width: 350px;
}

.label {
    display: flex;
    margin: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 400;
}

.span {
    font-weight: bold;
}

.detalles {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.tipoDocto {
    justify-content: center;
    width: 50px;

}


.cellNumeroDoc {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;


}

.cellVencimiento {

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    min-width: 100px;
}

.cellEstado {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 90px;
    min-width: 100px;
}

.encabezadosmonto {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 10px;
}

.encabezadosestado {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-left: 5px;
}

.encabezadosnumero {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-left: 15px;
}

.encabezadosdcto {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}


.box {
    text-align: center;
    border-radius: 5px;
    background-color: white;
    padding: 10px 0;
    width: 100%;
    height: auto;
    box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.2);
    margin-left: 280px;


}

.text-center {
    text-align: center;
    border-radius: 20px;
    font-size: 15px;
    font-weight: normal;
}

.boton-completo {
    width: 50%;
    border-radius: 20px;
    padding-top: 30px;
}

.Image {
    width: 50px;
    height: 50px;
}

.box2 {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    background-color: white;
    padding: 10px 0;
    max-width: 700px;
    height: auto;
    left: 20%;
    margin-left: 280px;
    width: 100%;
}

.Image-footer {
    width: 80px;
    height: 60px;
}

.row {
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.input {
    width: 50%;
    height: 30px;
    border-radius: 5px;
    border: 0.2px solid #a9a9a9;
    padding-left: 10px;

}

.black {
    margin: 0px 40px;
}

.myButton {
    background-color: #007c85;
    border-radius: 10px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 17px;
    padding: 10px 60px;
    margin-top: 10px;
    text-decoration: none;
}

.myButton:hover {
    background-color: #007c85;
}

.myButton:active {
    position: relative;
    top: 1px;
}

.button-container {
    padding-top: 20px;
}



@media screen and (min-width: 200px) and (max-width:700px) {
    .box {
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        width: 100%;
        height: auto;
        box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.2);
        margin-left: 0px;


    }

    .box2 {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        max-width: 700px;
        height: auto;
        left: 20%;
        margin-left: 0px;
        width: 100%;
    }


}

@media screen and (min-width: 800px) and (max-width:1024px) {
    .box {
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        width: 100%;
        height: auto;
        box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.2);
        margin-left: 0px;


    }

    .box2 {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        max-width: 700px;
        height: auto;
        left: 20%;
        margin-left: 0px;
        width: 100%;
    }


}

@media screen and (min-width: 1200px) and (max-width:1366px) {
    .box {
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        width: 100%;
        height: auto;
        box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.2);
        margin-left: 70px;


    }

    .box2 {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        max-width: 700px;
        height: auto;
        left: 20%;
        margin-left: 70px;
        width: 100%;
    }


}

@media screen and (min-width: 1400px) and (max-width:1600px) {
    .box {
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        width: 100%;
        height: auto;
        box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.2);
        margin-left: 100px;


    }

    .box2 {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        max-width: 700px;
        height: auto;
        left: 20%;
        margin-left: 100px;
        width: 100%;
    }


}



@media screen and (min-width: 1600px) and (max-width: 1680px) {
    .box {
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        width: 100%;
        height: auto;
        box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.2);
        margin-left: 170px;


    }

    .box2 {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        max-width: 700px;
        height: auto;
        left: 20%;
        margin-left: 170px;
        width: 100%;
    }






}

@media screen and (min-width: 1681px) and (max-width: 1900px) {
    .box {
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        width: 100%;
        height: auto;
        box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.2);
        margin-left: 250px;


    }

    .box2 {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        max-width: 700px;
        height: auto;
        left: 20%;
        margin-left: 250px;
        width: 100%;
    }

}

@media screen and (min-width: 2560px) and (max-width: 3840px) {
    .box {
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        width: 100%;
        height: auto;
        box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.2);
        margin-left: calc(50vh - 150px);


    }

    .box2 {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        background-color: white;
        padding: 10px 0;
        max-width: 700px;
        height: auto;
        left: 20%;
        margin-left: calc(50vh - 150px);
        width: 100%;
    }






}
.rojo {
    color: red;
    font-weight: normal;
    font-size: 35px;
    font-family: Arial;

}

.black {
    color: black;
    font-weight: normal;
    font-size: 25px;
    padding-bottom: 20px;
}
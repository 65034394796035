.navbar {    
       display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 80px;
    color: rgb(22, 22, 22);
    text-decoration: none;
    padding: 0 250px;
    box-shadow: 0 2px 4px 0 rgb(155 155 155 / 20%);
    position: relative;
}


.listItem {
    display: inline;
    margin-right: 50px;
    font-size: 14px;
    cursor: pointer;
    color: black;
    text-decoration: none;
    box-shadow: none;
    
}

.logo{
    width: 250px;
    text-align: center;
}
@media (max-width: 600px){
    .navbar {    
        display: flex;
     align-items: center;
     justify-content: space-evenly;
     height: 80px;
     color: rgb(22, 22, 22);
     text-decoration: none;
     padding: 0 200px;
     box-shadow: 0 2px 4px 0 rgb(155 155 155 / 20%);
     position: relative;
 }
 
    
    
}